import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import Home from "./components/Home";
import Registry from "./components/services/Registry";
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/jquery/dist/jquery.min'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/vendor/fontawesome-free/css/all.min.css'
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.css"
//
// import "@/assets/vendor/jquery/jquery.min.js"
// import "@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
// import "@/assets/vendor/jquery-easing/jquery.easing.min.js"
// import "@/assets/js/sb-admin-2.min.js"
// import "@/assets/vendor/datatables/jquery.dataTables.min.js"
// import "@/assets/vendor/datatables/dataTables.bootstrap4.min.js"
// import "@/assets/js/demo/datatables-demo.js"
import login from "./components/authentication/login";
import  store from "./store/index"
Vue.use(VueRouter);
Vue.config.productionTip = false
import axiosSetup from "./helpers/axios";
import router from "./components/navigation/routes/routes";

// call the axios setup method here
axiosSetup()
window.jQuery = window.$ =require('../node_modules/jquery/dist/jquery.min')





new Vue({
  render: h => h(App),
    router,
    store
}).$mount('#app')

