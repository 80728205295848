<template>
    <div>
        <div id="wrapper">
            <!-- Sidebar -->
            <Sidebar></Sidebar>
            <!-- End Sidebar -->
            <!-- Content Wrapper -->
            <div id="content-wrapper" class="d-flex flex-column">

                <!-- Main Content -->
                <div id="content">
                    <!-- Top Nav -->
                    <TopNav></TopNav>

                    <!-- End Nav -->
                    <!-- Begin Page Content -->
                    <div class="container-fluid">

                        <slot></slot>

                    </div>
                    <!-- /.container-fluid -->

                </div>
                <!-- End of Main Content -->

                <!-- Footer -->
                <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                            <span>Copyright &copy; Xarani (PVT) LTD {{currentYear}}</span>
                        </div>
                    </div>
                </footer>
                <!-- End of Footer -->

            </div>
            <!-- End of Content Wrapper -->

        </div>
        <!-- End of Page Wrapper -->

        <!-- Scroll to Top Button-->
        <a class="scroll-to-top rounded" href="#page-top">
            <i class="fas fa-angle-up"></i>
        </a>
        <!-- Logout Modal-->
        <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        <a class="btn btn-primary" @click="logout"><i class="fas fa-fw fa-cog"></i>
                            <span>Logout</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Sidebar from "../components/navigation/Sidebar";
    import TopNav from "../components/navigation/TopNav";

    export default {
        name: "dashboard",
        components: {
            TopNav,
            Sidebar

        },
        data: () => {
            return {
                currentYear: new Date().getFullYear(),
                timeoutID: ""
            };
        },
        created() {
            this.setup();
            console.log("timer started");

        },
        methods: {
            async logout(){
                        // localStorage.clear();
                        this.$store.commit("clearUserData");
                         window.location.href = '/login'
                    },
            setup() {
                // if (process.client) {
                    addEventListener("mousemove", this.resetTimer, false);
                    addEventListener("mousedown", this.resetTimer, false);
                    addEventListener("keypress", this.resetTimer, false);
                    addEventListener("DOMMouseScroll", this.resetTimer, false);
                    addEventListener("mousewheel", this.resetTimer, false);
                    addEventListener("touchmove", this.resetTimer, false);
                    addEventListener("MSPointerMove", this.resetTimer, false);

                    this.startTimer();
                    console.log("timer started from setup");
                // }
            },
            startTimer() {
                this.timeoutID = window.setTimeout(this.goInactive, 300000);


            },
            resetTimer(e) {
                window.clearTimeout(this.timeoutID);

                this.goActive();
            },
            goActive() {
                this.startTimer();
            },
            goInactive() {

                window.clearTimeout(this.timeoutID);
                this.$store.commit("clearUserData");
                alert('Your Session has ended');
                // this.$router.push("/login");
                window.location.href = '/login';
            },},

    }
</script>

<style scoped>

</style>