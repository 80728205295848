<template>

    <div>
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
<!--    <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i-->
<!--        class="fas fa-download fa-sm text-white-50"></i> Generate Report</a>-->
  </div>

  <!-- Content Row -->
  <div class="row">

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                Billing Month</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{billingMonth}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-calendar fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                Amount Owing (USD)</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                <semipolar-spinner v-if="isloading"
                                   :animation-duration="1500"
                                   :size="20"
                                   color="#ff1d5e"/>
                {{amountowing}} </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-warning shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                Number Of Requests ({{billingMonth}})</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800" >
                <semipolar-spinner v-if="isloading"
                                   :animation-duration="1500"
                                   :size="20"
                                   color="#ff1d5e"/>
                {{requests}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-comments fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
  import dashboard  from "../layouts/dashboard";
  import axios from "axios";
  import { SemipolarSpinner  } from 'epic-spinners';
export default {
  name: "home",
  components: {
    SemipolarSpinner
  },
  created() {
    this.$emit(`update:layout`, dashboard);
    this.getStats()
  },
  data: () => {
    return {
      billingMonth: new Date().getMonth()+1+'-'+new Date().getFullYear(),
      year: new Date().getFullYear(),
      isloading: false,
      requests: '',
      amountowing: ''
    };
  },
  methods:{
    async getStats(){
      this.isloading = true;
      await new Promise(resolve => setTimeout(resolve, 2000));
      let month1 = new Date().getMonth()+1;
      let mwedzi = month1.toString();
      if(mwedzi.length === 1){
        let month2 = '0'+mwedzi;
        console.log(month2 + 'ndini mwedzi');
        try{
          let JWTToken = this.$store.getters.accessToken;
          axios.get('https://xarani.net/billing/transactions/?period='+month2+'-'+this.year, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
                  .then(res => {
                    if(res.status === 200){
                      this.profiledata = res.data;
                      console.log('profile is:', this.profiledata[0].rg[0].requests_count);
                      console.log(new Date().getMonth()+1+'-'+new Date().getFullYear());
                      this.requests = this.profiledata[0].rg[0].requests_count;
                      this.amountowing = this.profiledata[0].owing_amount.toFixed(2);
                      this.responsedata = true;
                      this.isloading = false;
                    }else{
                      window.clearTimeout(this.timeoutID);
                      this.$store.commit("clearUserData");
                      alert('Your Session has expired');
                      // this.$router.push("/login");
                      window.location.href = '/login';
                    }
                  })
                  .catch(error => console.log(error))
        }catch(e){
          console.error(e)
        }
      }else{
        try{
          let JWTToken = this.$store.getters.accessToken;
          axios.get('https://xarani.net/billing/transactions/?period='+month1+'-'+this.year, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
                  .then(res => {
                    if(res.status === 200){
                      this.profiledata = res.data;
                      console.log('profile is:', res.data);
                      console.log(new Date().getMonth()+1+'-'+new Date().getFullYear());
                      this.responsedata = true;
                      this.isloading = false;
                    }else{
                      window.clearTimeout(this.timeoutID);
                      this.$store.commit("clearUserData");
                      alert('Your Session has expired');
                      // this.$router.push("/login");
                      window.location.href = '/login';
                    }

                  })
                  .catch(error => console.log(error))
        }catch(e){
          console.error(e)
        }
      }

    },
  }

};
</script>

<style scoped>

</style>