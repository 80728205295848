<template>
  <div>
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">ID Verification</h1>

    </div>

    <div class="row">
      <!-- Content Column -->
      <div class="col-lg-8 mb-4 animated bounceInUp">
        <!-- Color System -->

        <div class="card shadow mb-4">
          <div class="card-body">
            <form method="get" class="form-inline row " @submit.prevent="regcall">
              <div class="col-sm-4 mb-3 mb-sm-0">
                <input type="text"  name="username" class="form-control" v-model="idnumber" placeholder="ID Number">
              </div>
              <div class="col-sm-3">
                <button type="button" @click="regcall" class="btn btn-primary mb-2">Confirm identity</button>
              </div>
              <div class="col-sm-3">
                <a type="submit" @click="clear" class="btn btn-warning mb-2">Clear Results</a>
              </div>
            </form>

          </div>
          <br>
          <semipolar-spinner class="spinner" v-if="isloading"
                             :animation-duration="2000"
                             :size="40"
                             color="#ff1d5e"
          />
          <hr class=" ml-3 mr-3 sidebar-divider" >
        <div class="card shadow mb-4 ml-4 mr-4" v-if="responsedata">

          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Returned Results</h6>
          </div>
          <table class="table table-hover" >

            <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Person Name / Names</td>
              <td></td>
              <td>{{profiledata.firstName}}</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Person Surname</td>
              <td></td>
              <td>{{profiledata.surname}}</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Person ID Number</td>
              <td></td>
              <td v-if="profiledata.status === 'E'" class="table-danger">{{profiledata.personNo}}  Not Valid</td>
              <td v-else >{{profiledata.personNo}}</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Person Gender</td>
              <td></td>
              <td v-if="profiledata.sex === 'F'">Female</td>
              <td v-else-if="profiledata.sex === 'M'">Male</td>
              <td v-else>Null</td>
            </tr>

            <tr>
              <th scope="row">5</th>
              <td>Person Date Of Birth</td>
              <td></td>
              <td>{{profiledata.dateOfBirth}}</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Person Birth Place</td>
              <td></td>
              <td>{{profiledata.birthPlace}}</td>
            </tr>
            <tr v-if="profiledata.status === 'D'">
              <th scope="row">7</th>
              <td>Person Date Of Death</td>
              <td></td>
              <td>{{profiledata.dateOfDeath}}</td>
            </tr>

            <tr >
              <th scope="row">8</th>
              <td>Person Life Status</td>
              <td></td>
              <td v-if="profiledata.status === 'A'" class="table-success">Active</td>
              <td v-else-if="profiledata.status === 'E'" class="table-danger">Record Error / Not Valid</td>
              <td v-else-if="profiledata.status === 'D'" class="table-warning">Female</td>
              <td v-else>Null</td>
            </tr>
            <hr class=" ml-3 mr-3 sidebar-divider" >
            <tr >
              <th scope="row"></th>
              <td></td>
              <td></td>
              <td v-if="profiledata.status === 'A' || profiledata.status === 'D'" >
                <button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                        data-toggle="modal" data-target=".bd-example-modal-lg"><i class="fas fa-file-pdf fa-sm text-white-50 pdf" ></i> Generate Report</button>


              </td>

              <td v-else></td>
            </tr>
            </tbody>
          </table>

        </div>
        </div>

      </div>
      <div class="col-lg-4">

        <div class="card position-relative">
          <div class="card-body">
            <img class="card-img-top"
                 src="../../assets/img/undraw_Hire_re_gn5j.svg">
            <hr>
          </div>
        </div>

      </div>
    </div>

<!--    HTML REPORT-->
    <div class="modal fade bd-example-modal-lg"  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" >

          <div class="container" id="capturePdf">
              <div class="row">
                  <div class="col-md-12 body-main">
                    <div class="row">
                      <div class="col-md-4" v-if="false">
                        <img class="img" alt="Invoce Template" src="../../assets/img/undraw_Hire_re_gn5j.svg" />
                      </div>
                      <div class="col-md-8 text-right" v-if="false">
                        <h4 style="color: #F81D2D;"><strong>Zara</strong></h4>
                        <p>221 ,Baker Street</p>
                        <p>1800-234-124</p>
                        <p>example@gmail.com</p>
                      </div>
                    </div> <br />

                    <div>
                      <table class="table table-hover" >

                        <tbody>
                        <tr>

                          <td>Person Name / Names</td>
                          <td></td>
                          <td>{{profiledata.firstName}}</td>
                        </tr>
                        <tr>

                          <td>Person Surname</td>
                          <td></td>
                          <td>{{profiledata.surname}}</td>
                        </tr>
                        <tr>

                          <td>Person ID Number</td>
                          <td></td>
                          <td v-if="profiledata.status === 'E'" class="table-danger">{{profiledata.personNo}}  Not Valid</td>
                          <td v-else >{{profiledata.personNo}}</td>
                        </tr>
                        <tr>

                          <td>Person Gender</td>
                          <td></td>
                          <td v-if="profiledata.sex === 'F'">Female</td>
                          <td v-else-if="profiledata.sex === 'M'">Male</td>
                          <td v-else>Null</td>
                        </tr>

                        <tr>

                          <td>Person Date Of Birth</td>
                          <td></td>
                          <td>{{profiledata.dateOfBirth}}</td>
                        </tr>
                        <tr>

                          <td>Person Birth Place</td>
                          <td></td>
                          <td>{{profiledata.birthPlace}}</td>
                        </tr>
                        <tr v-if="profiledata.status === 'D'">

                          <td>Person Date Of Death</td>
                          <td></td>
                          <td>{{profiledata.dateOfDeath}}</td>
                        </tr>

                        <tr >

                          <td>Person Life Status</td>
                          <td></td>
                          <td v-if="profiledata.status === 'A'" class="table-success">Active</td>
                          <td v-else-if="profiledata.status === 'E'" class="table-danger">Record Error / Not Valid</td>
                          <td v-else-if="profiledata.status === 'D'" class="table-warning">Deceased</td>
                          <td v-else>Null</td>
                        </tr>
                        <hr class=" ml-3 mr-3 sidebar-divider" >

                        </tbody>
                      </table>

                    </div>
                    <div>
                      <div class="col-md-12">
                        <p><b>Date :</b> {{new Date().getDate()+'-'+ new Date().getMonth()+'-'+ new Date().getFullYear()}}</p> <br />
                        <p><b>Signature</b></p>
                      </div>
                  </div>
                </div>
              </div>

          </div>
          <button type="submit" @click="generatePDF" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i class="fas fa-file-pdf fa-sm text-white-50 pdf" ></i> Generate Report</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dashboard from "../../layouts/dashboard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SemipolarSpinner  } from 'epic-spinners';
export default {
  name: "Registry",
  components: {
    SemipolarSpinner
  },
  created() {
    this.$emit(`update:layout`, dashboard);
  },
  data() {
    return {
      idnumber: "",
      responsedata: false,
      profiledata: "",
      isloading: false,

    }
  },
  methods:{
     async regcall(){
      this.isloading = true;
      await  new Promise(resolve => setTimeout(resolve, 2000));
      try{
      let JWTToken = this.$store.getters.accessToken;
      axios.get('https://xarani.net/service/person/?id_number='+this.idnumber, { headers: {"Authorization" : `Bearer ${JWTToken}`} })
              .then(res => {
                if(res.status === 200){
                  this.profiledata = res.data;
                  console.log('profile is:', res.data);
                  console.log(new Date().getMonth()+1+'-'+new Date().getFullYear());
                  this.responsedata = true;
                  this.isloading = false;
                }else{
                  window.clearTimeout(this.timeoutID);
                  this.$store.commit("clearUserData");
                  alert('Your Session has ended');
                  // this.$router.push("/login");
                  window.location.href = '/login';
                }

              })
              .catch(error => console.log(error))
      }catch(e){
        console.error(e)
      }
    },

    async generatePDF(){
      let pdf = new jsPDF("p","pt","a2");
      let element = document.getElementById('capturePdf');
      let width= element.style.width;
      let height = element.style.height;
      html2canvas(element).then(canvas => {
        let image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', 2, 2, width, height);
        pdf.save('test.pdf')
      });
    },

    async clear(){
      this.responsedata = false,
      this.idnumber = ""
    }

  },

};
</script>

<style scoped>
  .pdf{ color:red}
  .spinner{
    margin-left: 30em;
  }
  
  .body-main {
    background: #ffffff;
    border-bottom: 15px solid #1E1F23;
    border-top: 15px solid #1E1F23;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 40px 30px !important;
    position: relative;
    box-shadow: 0 1px 21px #808080;
    font-size: 10px;
    width:200px;
  }

  .main thead {
    background: #1E1F23;
    color: #fff
  }

  .img {
    height: 80px
  }

  h1 {
    text-align: center
  }
</style>