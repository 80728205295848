import Home from "../../Home";
import login from "../../authentication/login";
import Registry from "../../services/Registry";
import VueRouter from "vue-router";
import store from "../../../store/index";


const  routes = [
    {
        path: '/',
        component:Home,
        beforeEnter: (to, from, next) => {
            if(store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },
    {
        path: '/login',
        component: login},
    {
        path: '/identity',
        component:Registry,
        beforeEnter: (to, from, next) => {
            if(store.state.isAuthenticated === false) {
                next("/login");
            } else {
                next();
            }
        }
    },

];

const  router = new VueRouter(
    {
        routes,
        mode: 'history'
    }
);

// router.beforeEach((to) =>{
//     console.log(to);
// })

export default router;